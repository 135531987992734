'use client';
import { Dialog, Portal } from '@ark-ui/react';
import { usePathname } from 'next/navigation';
import { PropsWithChildren, useCallback } from 'react';
import { useClientOnlyMediaQuery } from '~/common/hooks/use-client-only-media-query';
import { Media } from '~/utils/styles/enums';
import { cva } from '@styled-system/css';
import { styled } from '@styled-system/jsx';

export type ModalProps = {
  isOpen: boolean;
  onClose?: () => void;
};

type Props = PropsWithChildren<ModalProps>;

export const SearchModal = ({ isOpen, onClose, children }: Props) => {
  const handleOnOpenChange = useCallback(
    ({ open }: { open: boolean }) => {
      if (!open) {
        onClose?.();
      }
    },
    [onClose]
  );
  const screenLarge = useClientOnlyMediaQuery(Media.LARGE);
  const currentPath = usePathname();

  const hasInstallAppHeader = !currentPath?.includes('/auth');

  return (
    <Dialog.Root
      lazyMount
      unmountOnExit
      modal={false}
      open={isOpen}
      onOpenChange={handleOnOpenChange}
      closeOnEscape={false}
      closeOnInteractOutside={screenLarge}
    >
      <Portal>
        <Backdrop />
        <Dialog.Positioner
          className={positionerStyle({
            visual: hasInstallAppHeader ? 'install_app' : 'not_install_app',
          })}
        >
          <Content>{children}</Content>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  );
};

const Backdrop = styled(Dialog.Backdrop, {
  base: {
    display: { base: 'none', lg: 'block' },
    backdropFilter: 'blur(4px)',
    background: 'rgba(0, 0, 0, 0.5)',
    height: '100vh',
    left: '0',
    position: 'fixed',
    top: '0',
    width: '100vw',
    zIndex: 'modal_backdrop',
    _open: {
      animation: 'fadein 150ms ease-out',
    },
    _closed: {
      animation: 'fadeout 150ms ease-out',
    },
  },
});

const positionerStyle = cva({
  base: {
    alignItems: { base: 'baseline', lg: 'center' },
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    bottom: {
      base: 0,
      lg: 24,
    },
    left: {
      base: 0,
      lg: undefined,
    },
    right: {
      base: 0,
      lg: undefined,
    },
    margin: {
      base: 0,
      lg: 'auto',
    },
    width: {
      base: '100dvw',
      lg: '600px',
    },
    zIndex: 'teller_modal',
  },
  variants: {
    visual: {
      install_app: {
        top: {
          base: '93px',
          lg: 24,
        },
        height: {
          base: 'calc(100dvh - 54px - 93px)', // minus bottom navigation, install app header and header
          lg: 'calc(100dvh - 48px)',
        },
      },
      not_install_app: {
        top: {
          base: '40px',
          lg: 24,
        },
        height: {
          base: 'calc(100dvh - 54px - 40px)', // minus bottom navigation and header
          lg: 'calc(100dvh - 48px)',
        },
      },
    },
  },
});

const Content = styled(Dialog.Content, {
  base: {
    background: 'white',
    boxShadow: 'lg',
    borderRadius: {
      base: 0,
      lg: '8px',
    },
    height: '100%',
    width: '100%',
    _open: {
      animation: 'fadein 150ms ease-out',
    },
    _closed: {
      animation: 'fadeout 150ms ease-out',
    },
  },
});
